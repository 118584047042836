export const API = '/api';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const AI_QUESTION_MAX_LENGTH = 500;
export const AI_ANSWER_MAX_LENGTH = 3000;

// 机器人可调用知识库最大数量
export const BOT_MAX_KNOWLEDGE = 20;

export const SENTRY_DISABLED = String('sentry-disabled-bool') === 'sentry-disabled-true';
