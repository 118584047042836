import { pick } from "lodash";
import { generateAxiosOptions, get, post } from "../utils/client";
import { makeMapWithTranslate } from "@utils/translate";

export const LoginWithEmailPasswordCodeMap = makeMapWithTranslate({
  '-1': '无效的账号或密码',
  '-2': '账号没有设置密码',
});

export const LoginWithEmailCodeCodeMap = makeMapWithTranslate({
  '-1': 'code无效',
  '-2': 'code过期',
  '-3': 'email无效（没有发送过验证码）',
  '-4': '未知错误',
});

export const authApi = {
  getUserInfo(params: { token?: string } = {}) {
    const { token, ...rest } = params;
    const options = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined;
    return get<WithCode<IUser>>('/user/info', {...rest}, options);
  },
  createOrg(params: { name: string, logo?: string, token?: string; ownerName?: string; orgPhone: string; industry?: string; validateCode: string; ownerPosition?: string; orgScale?: string }) {
    const { token, ...rest } = params;
    const options = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined;
    return post<WithCode<IOrg>>('/org/create', { ...rest }, options);
  },
  loginWithCode(params: { tempAuthCode: string }) {
    return post<WithCode<{ user: IUser, token: string, orgId?: string }>>('/user/login/temp-auth-code', params);
  },
  loginWithEmailCode(params: { email: string, code: string, password?: string }) {
    return post<WithCode<{ user: IUser, token: string }>>('/user/login/email-code', params);
  },
  loginWithEmailPassword(params: { email: string, password: string }) {
    return post<WithCode<{ user: IUser, token: string }>>('/user/login/email-password', params);
  },
  sendEmailCode(params: { email: string }) {
    return post<WithCode<null>>('/user/email/code', params);
  },
  sendEmailCodeForUser(params: { email: string }) {
    return post<WithCode<null>>('/user/email/send-code-for-user', params);
  },
  setPassword(params: { userId: string, password: string, token?: string }) {
    return post<WithCode<null>>('/user/set-password', pick(params, ['userId', 'password']), generateAxiosOptions({}, params.token));
  },
  getTempAuthCode(params: { userId?: string }) {
    return get<{ tempAuthCode: string; code: number }>('/temp-auth-code/create', params);
  },
  sendValidateCode(params: { orgId: string; userId: string; phone: string; token?: string }) {
    const { token, ...rest } = params;
    const options = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined;
    return post('/org/send-validate-code', { ...rest }, options);
  },
  startTrial(params: { ownerName?: string; orgPhone: string; industry?: string; validateCode: string; ownerPosition?: string; orgScale?: string }) {
    return post<WithCode<any>>('/org/start-trial', params);
  },
  getUserByAuthCode(params: { code: string }) {
    return post<WithCode<{ token: string; userInfo: IUser; orgId: string }>>('/user/login/insight/temp-auth-code', params);
  },
  resetPassWord(params: { originalPassword?: string; code?: string; password: string }) {
    return post<WithCode<{ data: any }>>('/user/update-password', params);
  },
  updateOrg(params: { logo: string; timezone: string }) {
    return post<WithCode<{ data: any }>>('/org/update', params);
  },
  updateUser(params: { avatar?: string, name?: string }) {
    return post<WithCode<{ data: any }>>('/user/update-user-info', params);
  },
  leaveOrg(params: { orgId: string }) {
    return post<WithCode<{ data: any }>>('/user/leave-org', params);
  },
}
